<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12 sm12>
                <v-card class="primary--outlined" outlined>
                    <v-card-title class="title">
                        <v-icon>layers</v-icon> &nbsp;{{$route.query.name}}
                        <v-spacer></v-spacer>
                        <transition name="animate-css-transition" enter-active-class="animated fadeInRight"
                                    leave-active-class="animated fadeOutRight">
                        </transition>
                    </v-card-title>
                    <v-card outlined>
                        <v-card-title class="title pb-0">
                            <v-flex xs4>
                                <v-select :items="grades" class="pa-0" label="Grade" outlined dense
                                        v-model="grade"/>
                            </v-flex>
                            <v-flex xs4>
                                <v-calendar-field v-model="frmDate" id-val="from_date" label="From Date"></v-calendar-field>
                            </v-flex>
                            <v-flex xs4>
                                <v-calendar-field v-model="toDate" id-val="to_date" label="To Date"></v-calendar-field>
<!--                                <v-text-field autocomplete="off" v-mask="'####-##-##'" prepend-icon="event" class="pa-0"-->
<!--                                            label="To Date" outlined dense-->
<!--                                            v-model="toDate"/>-->
                            </v-flex>
                        </v-card-title>
                    </v-card>

                    <v-data-table
                            :headers="headers"
                            :items="form.items.data"
                            :footer-props="footerProps"
                            :options.sync="pagination"
                            :server-items-length="form.items.meta.total"
                            :loading="form.loading">
<!--                        <template v-slot:item="{index, item}">-->
<!--                            <tr>-->
<!--                                <td>{{ index + form.items.meta.from }}</td>-->
<!--                                <td class="text-xs-left">-->
<!--                                    {{ item.name }}<sup>"{{item.section}}"</sup>-->
<!--                                </td>-->
<!--                                <td class="text-xs-center">{{item.present}}</td>-->
<!--                                <td class="text-xs-center">{{item.absent}}</td>-->
<!--                                <td class="text-xs-center">{{item.late }}</td>-->
<!--                                <td class="text-xs-center">{{item.leave}}</td>-->
<!--                                <td class="text-xs-center">{{item.total_attendance_count}}-->
<!--                                </td>-->
<!--                            </tr>-->

<!--                        </template>-->
                        <v-alert slot="no-results" :value="true" color="error" icon="warning">
                            Your search for "{{ search }}" found no results.
                        </v-alert>

                    </v-data-table>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="success"
                               outlined
                               @click="save" :disabled="is_publish === 1">Analyze
                        </v-btn>
                    </v-card-actions>

                </v-card>
            </v-flex>
            <v-dialog v-model="form.dialog" persistent max-width="500px">
                <v-card>
                    <v-card-title class="primary white--text">
                        <span class="title">Add/Update</span>
                    </v-card-title>
                    <v-card-text class="pb-1">
                        <v-form ref="form" @submit.prevent="save"
                                @keydown.native="form.errors.clear($event.target.name)" @keyup.enter="save"
                                lazy-validation>
                            <v-text-field autocomplete="off" label="Exam Name*" hint="Eg: First Terminal Examinations"
                                          required class="pa-0 pt-3"
                                          v-model="form.name"
                                          name="name" :error-messages="form.errors.get('name')"/>

                            <v-select :items="examType" class="pa-0 pt-3" label="Exam Type"
                                      v-model="form.exam_type"/>

                            <!--<v-checkbox v-model="is_final" label="Is Final"/>-->

                            <v-flex xs8>
                                <v-checkbox v-if="form.exam_type==='exam'" color="blue" v-model="is_final">
                                    <template v-slot:label>
                                        <div style="color:#333;">
                                            <!--Subject will be-->
                                            <strong>{{is_final===false?'Check to mark as':'Marked as '}}</strong>
                                            final terminal.
                                            <p style="line-height: 10px">
                                                <small style="color:#666;font-size: 10px;">
                                                    One among various examinations should be marked as final.
                                                </small>
                                            </p>
                                        </div>
                                    </template>
                                </v-checkbox>
                            </v-flex>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="warning" outlined @click="form.dialog = false, form.reset()">Close</v-btn>
                        <v-btn color="success" outlined @click="save">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>
    </v-container>
</template>
<script>
    import {mapState} from 'vuex'
    import Form from '@/library/Form'
    import Mixins from '@/library/Mixins'

    export default {
        mixins: [Mixins],
        data: () => ({
            form: new Form({
                attendance_from: '',
                attendance_to: '',
                exam_id: '',
                grade_id: '',
            }, '/api/exam-attendance'),
            grades: [],
            grade: '',
            frmDate: '',
            toDate: '',
            is_publish: 0,
            search: null,
            exam_id: '',
            pagination: {
                itemsPerPage: 50,

            // descending: true
            },
            examType: [{value: 'exam', text: 'Exam'}, {value: 'test-exam', text: 'Test'}],
            is_final: false,
            headers: [
                {text: 'Roll', align: 'center', value: 'roll', width: '5'},
                {text: 'Name', align: 'center', value: 'name'},
                {text: 'Section', align: 'center', value: 'section'},
                {text: 'Present', align: 'center', value: 'present'},
                {text: 'Absent', align: 'center', value: 'absent'},
                {text: 'Late', align: 'center', value: 'late'},
                {text: 'Leave', align: 'center', value: 'leave'},
                {text: 'Total Attendance Count', align: 'center', value: 'total_attendance_count'},
            ],

        }),

        computed: {
            ...mapState(['batch'])
        },

        watch: {
            'pagination': function () {
                this.get();
            },
            'batch': function (value) {
                this.get();
            },
            'grade': function () {
                this.getDates();
            },
            'frmDate': function (v) {
                this.frmDate = v.dateForm()
            },
            'toDate': function (v) {
                this.toDate = v.dateForm()
            }
        },
        mounted() {
            this.getGrades();
            this.exam_id = this.$route.query.id;
        },

        methods: {
            // queryString() {
            //     let json = this.pagination;
            //     return '?' +
            //         Object.keys(json).map(function (key) {
            //             if (![null, undefined].includes(json[key]))
            //                 return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
            //         }).join('&') + '&examId=' + this.exam_id + '&frmDate=' + this.frmDate + '&toDate=' + this.toDate + '&gradeId=' + this.grade;
            // },

            get(params) {
                let extraParams = '&examId=' + this.exam_id + '&frmDate=' + this.frmDate + '&toDate=' + this.toDate + '&gradeId=' + this.grade;
                if (this.grade && this.frmDate && this.toDate) {
                    // console.log(params,'testing...');
                    let query = [null, undefined].includes(params) ? this.queryString() : params;
                    this.form.get(null, this.queryString(extraParams)).then(({data}) => {
                        this.pagination.totalItems = data.meta.total;
                        this.is_publish = data.is_publish;
                    })
                }

            },

            save() {
                this.form.attendance_from = this.dateFormat(this.frmDate);
                this.form.attendance_to = this.dateFormat(this.toDate);
                this.form.grade_id = this.grade;
                this.form.exam_id = this.exam_id;
                this.form.fireFetch = false;
                this.form.store().then(() => {
                    this.get();
                });
            },

            editExam(item) {
                if (item.is_final === 1) this.is_final = true;
                else this.is_final = false;
            },
            viewExam(id) {
                this.$router.push({
                    name: 'exam-detail',
                    params: {
                        examId: id
                    },
                })
            },
            getGrades() {
                this.$rest.get('/api/grades?rowsPerPage=25&sortBy=rank&descending=false&roleWise=true').then(({data}) => {
                    this.grades = data.data.map(item => {
                        return {value: item.id, text: item.name}
                    });
                })
            },
            getDates() {
                this.$rest.get('/api/exam/exam/exam-attendance-date?gradeId=' + this.grade + '&examId=' + this.$route.query.id).then(({data}) => {
                    // console.log('testing');
                    this.frmDate = data.fromDate;
                    this.toDate = data.toDate;
                    this.get();
                });
            },
            dateFormat(data) {
                if (data.length === 8) return data = data.substr(0, 4) + '-' + data[4] + data[5] + '-' + data.slice(-2);
                else return data;
            },

        }
    }
</script>
<style lang="scss" scoped></style>